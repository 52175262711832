import React from "react";
import Img1 from "../img/portfolio/1.jpg";
import Img2 from "../img/portfolio/2.jpg";
import Img3 from "../img/portfolio/3.jpg";
import Img4 from "../img/portfolio/4.jpg";
import Img5 from "../img/portfolio/5.jpg";
// import Img6 from "../img/portfolio/6.jpg";
import Img10 from "../img/portfolio/10.jpg";
import Img11 from "../img/portfolio/11.jpg";
import Img12 from "../img/portfolio/12.jpg";
import Img13 from "../img/portfolio/13.jpg";
import Img15 from "../img/portfolio/15.jpg";
import Img16 from "../img/portfolio/16.jpg";
import Img18 from "../img/portfolio/18.jpg";
import Img19 from "../img/portfolio/19.jpg";
import Img20 from "../img/portfolio/20.jpg";
import Img21 from "../img/portfolio/21.jpg";
import Img22 from "../img/portfolio/22.jpg";
import Img23 from "../img/portfolio/23.jpg";
import Img24 from "../img/portfolio/24.jpg";
import Img25 from "../img/portfolio/25.jpg";
import Img26 from "../img/portfolio/26.jpg";
import Img27 from "../img/portfolio/27.jpg";
import Img28 from "../img/portfolio/28.jpg";
import Img29 from "../img/portfolio/29.jpg";
import Img30 from "../img/portfolio/30.jpg";
import Img31 from "../img/portfolio/31.jpg";
import Img32 from "../img/portfolio/32.jpg";
import Img33 from "../img/portfolio/33.jpg";
import Img34 from "../img/portfolio/34.jpg";
import Img35 from "../img/portfolio/35.jpg";
import Img36 from "../img/portfolio/36.jpg";
import Img37 from "../img/portfolio/37.jpg";
import Img38 from "../img/portfolio/38.jpg";
import Img39 from "../img/portfolio/39.jpg";
import Img40 from "../img/portfolio/40.jpg";
import { Link } from "react-router-dom";

const Gallery = () => {
  return (
    <div>
      <div className="header-title px-24 py-32">
        <Link to={'/'} className="text-[25px] text-white">Home</Link>
        <h2 className="font-bold text-[60px] text-white">Gallery</h2>
      </div>
      <div className="container mx-auto px-5 py-2 lg:px-32 lg:pt-24">
        <div className="-m-1 flex flex-wrap md:-m-2">
          <div className="flex w-full flex-wrap">
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img1}
              />
            </div>

            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img2}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img3}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img4}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img5}
              />
            </div>
            {/* <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img6}
              />
            </div> */}
            {/* <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img7}
              />
            </div> */}
            {/* <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img8}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img9}
              />
            </div> */}
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img10}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img11}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img12}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img13}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img15}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img16}
              />
            </div>
            {/* <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img17}
              />
            </div> */}

            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img18}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img19}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img20}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img21}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img22}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img23}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img24}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img25}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img26}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img27}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img28}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img29}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img30}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img31}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img32}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img33}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img34}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img35}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img36}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img37}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img38}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img39}
              />
            </div>
            <div className="lg:w-1/4 w-1/2 p-1 md:p-2">
              <img
                alt="gallery"
                data-aos="flip-left"
                className="block h-full w-full rounded-lg object-cover object-center h-[400px]"
                src={Img40}
              />
            </div>
            

          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;
