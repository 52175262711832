import React from "react";
import {
  Navbar,
  MobileNav,
  Typography,
  Button,
  IconButton,
  Card,
  Drawer,
} from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../img/logo.png";

import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";

const Header = () => {
  const [openNav, setOpenNav] = React.useState(false);
  const [openRight, setOpenRight] = React.useState(false);
  const navigate = useNavigate();

  const openDrawerRight = () => setOpenRight(true);
  const closeDrawerRight = () => setOpenRight(false);

  React.useEffect(() => {
    window.addEventListener(
      "resize",
      () => window.innerWidth >= 960 && setOpenNav(false)
    );
  }, []);

  const navList = (
    <ul className="mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-0 overflow-y-scroll lg:overflow-hidden max-h-96 lg:max-h-auto">
      <Link
        
        to={"/"}
        className="flex items-center text-white"
      >
        <img src={Logo} className="w-[210px] my-2 lg:block hidden" srcset="" />
      </Link>
      <Link
        
        to={"/"}
        className="flex items-center text-white"
      >
        <Typography
          as="li"
          variant="lg"
          className="p-8 py-8 font-bold font-bold"
        >
          Home
        </Typography>
      </Link>
      <Link
        to={"/about"}
        
        className="flex items-center hover:text-black-900 text-white font-bold hover:text-[#0035A7]"
      >
        <Typography as="li" variant="lg" className="p-8 py-8 font-bold ">
          About
        </Typography>
      </Link>
      <Link
        to={"/gallery"}
        
        className="flex items-center hover:text-black-900 text-white font-bold hover:text-[#0035A7]"
      >
        <Typography as="li" variant="lg" className="p-8 py-8 font-bold ">
          Gallery
        </Typography>
      </Link>
      <Link
        to={"/services"}
        
        className="flex items-center hover:text-black-900 text-white font-bold hover:text-[#0035A7]"
      >
        <Typography as="li" variant="lg" className="p-8 py-8 font-bold ">
          Services
        </Typography>
      </Link>
      <Link
        to={"/contact"}
        
        className="flex items-center hover:text-black-900 text-white font-bold hover:text-[#0035A7]"
      >
        <Typography as="li" variant="lg" className="p-8 py-8 font-bold ">
          Contact
        </Typography>
      </Link>
    </ul>
  );

  const navListLg = (
    <ul className="mt-2 flex flex-col gap-2 lg:mb-0 lg:mt-0 lg:flex-row lg:items-center lg:gap-0">
      <Link to={"/"} className="flex items-center text-black-900">
        <Typography
          as="li"
          variant="lg"
          className="p-8 py-8 font-bold bg-[#0035A7] font-bold"
        >
          Home
        </Typography>
      </Link>
      <Link
        to={"/about"}
        className="flex items-center hovertext-black-900 text-white font-bold hover:bg-[#0035A7]"
      >
        <Typography as="li" variant="lg" className="p-8 py-8 font-bold ">
          About
        </Typography>
      </Link>
      <Link
        to={"/gallery"}
        className="flex items-center hovertext-black-900 text-white font-bold hover:bg-[#0035A7]"
      >
        <Typography as="li" variant="lg" className="p-8 py-8 font-bold ">
          Gallery
        </Typography>
      </Link>
      <Link
        to={"/services"}
        className="flex items-center hovertext-black-900 text-white font-bold hover:bg-[#0035A7]"
      >
        <Typography as="li" variant="lg" className="p-8 py-8 font-bold ">
          Services
        </Typography>
      </Link>
      <Link
        to={"/contact"}
        className="flex items-center hovertext-black-900 text-white font-bold hover:bg-[#0035A7]"
      >
        <Typography as="li" variant="lg" className="p-8 py-8 font-bold ">
          Contact
        </Typography>
      </Link>
    </ul>
  );
  return (
    <>
      <Drawer
        placement="right"
        open={openRight}
        onClose={closeDrawerRight}
        className="p-4 bg-[#333333]"
      >
        <div className="mb-6 flex items-center justify-between">
          <Typography variant="h5" color="white">
            Perdomo Roofing Corp
          </Typography>
          <IconButton
            variant="text"
            color="blue-gray"
            onClick={closeDrawerRight}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-5 w-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </IconButton>
        </div>
        <ul>
          <li className="p-4" >
            <Link className="text-white" onClick={closeDrawerRight} to={"/"}>Home</Link>
          </li>
          <li className="p-4" >
            <Link className="text-white" onClick={closeDrawerRight} to={"/about"}>About</Link>
          </li>
          <li className="p-4" >
            <Link className="text-white" onClick={closeDrawerRight} to={"/services"}>Services</Link>
          </li>
          <li className="p-4" >
            <Link className="text-white" onClick={closeDrawerRight} to={"/gallery"}>Gallery</Link>
          </li>
          <li className="p-4" >
            <Link className="text-white" onClick={closeDrawerRight} to={"/contact"}>Contact</Link>
          </li>
        </ul>
      </Drawer>
      <Navbar className="lg:sticky inset-0 z-10 h-max max-w-full rounded-none border-none px-4 lg:px-32 lg:py-0 bg-[#000000]">
        <marquee className="lg:hidden w-full block py-2 pb-4 px-12 bg-[#0035A7]">
          <em className="text-white"> Perdomo Roofing Corp | </em>
          <i className="fa fa-flag-o"></i>{" "}
          <span className="text-white">
            {" "}
            6130 Steve Scarlett Pl La Vergne TN 37086 |{" "}
          </span>
          <i className="fa fa-flag-o"></i>{" "}
          <span className="text-white"> 10 years of Experience | </span>{" "}
          <i className="fa fa-flag-o"></i>{" "}
          <span className="text-white"> orvinperdomo1982@gmail.com | </span>
          <i className="fa fa-flag-o"></i>{" "}
          <span className="text-white"> Free Estimates | </span>
        </marquee>
        <Link
          onClick={() => setOpenRight(!openRight)}
          to={"/"}
          className="flex items-center text-white lg:hidden block"
        >
          <img src={Logo} className="w-[380px] my-2" srcset="" />
        </Link>
        <div className="flex items-center justify-between text-blue-gray-900">
          <div className="flex w-screen items-center gap-4">
            <div className=" hidden lg:block">{navList}</div>
            <IconButton
              variant="text"
              className="ml-[50%] my-8 flex h-8 w-full text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent lg:hidden"
              ripple={false}
              onClick={() => setOpenRight(!openRight)}
            >
              {openRight ? (
                <div className="bg-[#17233B]">
                  <AiOutlineClose className="text-white text-xl" />
                </div>
              ) : (
                <div className="bg-[#17233B] p-4">
                  <AiOutlineMenu className="text-white text-xl" />
                </div>
              )}
            </IconButton>
          </div>
        </div>
        <div className="lg:hidden block">
          <MobileNav open={openNav}>{navList}</MobileNav>
        </div>

        {/* <div className="lg:block hidden">
          <MobileNav open={openNav}>{navListLg}</MobileNav>
        </div> */}
      </Navbar>
    </>
  );
};

export default Header;
