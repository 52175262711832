import React from 'react'
import ContactForm from '../components/ContactForm'
import { Link } from 'react-router-dom'
import Img from '../img/portfolio/8.jpg'

const Contact = () => {
  return (
    <div>
      <div className="header-title px-24 py-32">
        <Link to={'/'} className="text-[25px] text-white">Home</Link>
        <h2 className="font-bold text-[60px] text-white">Contact Us</h2>
      </div>

      {/* <div className='grid lg:grid-cols-2 grid-cols-1 gap-4 mt-4'  >
        <div className='flex justify-center items-center'>
          <img src={Img} className='lg:w-3/6' alt="" />
        </div>
      </div> */}

      <div className='bg-white shadow-md lg:p-8 lg:m-8 rounded-lg'>
        <ContactForm/>
      </div>
    </div>
  )
}

export default Contact