import React from "react";
import {
  Card,
  Input,
  Checkbox,
  Button,
  Textarea,
  Select,
  Option,
  List,
  ListItem,
  ListItemPrefix,
  Typography,

} from "@material-tailwind/react";
import Back1 from "../img/call.jpg";

const ContactForm = () => {
  return (
    <div className="mt-8">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-0 mt-8">
        <div className="flex justify-center items-center w-full">
          <Card color="transparent" shadow={false}>
            <Typography variant="h4" color="gray">
              Perdomo Roofing Corp
            </Typography>
            <Typography color="gray" className="mt-1 font-normal">
              Free Estimates Requirement or get a Quoter.
            </Typography>
            <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
              <div className="mb-4 flex flex-col gap-6">
                <Input color="gray" size="lg" label="Name" />
                <Input color="gray" size="lg" label="Last Name" />
                <Input color="gray" size="lg" label="Email" />
                <Input color="gray" size="lg" label="Address" />
                <Input color="gray" size="lg" label="Phone Number" />
                <Select label="Type You Services You Need">
                  <Option>Burnished Slate</Option>
                  <Option>Standing Seam Metal Roofing</Option>
                  <Option>TPO Roofing Installation/Repair</Option>
                  <Option>EPDM Roofing</Option>
                  <Option>Standing Seam Metal Roofinging Installation/Repair</Option>
                  <Option>General Roofing</Option>
                </Select>
                <h2>Best Way To Contact You</h2>
                <List className="flex-row">
                  <ListItem className="p-0">
                    <label
                      htmlFor="horizontal-list-react"
                      className="flex w-full cursor-pointer items-center px-3 py-2"
                    >
                      <ListItemPrefix className="mr-3">
                        <Checkbox
                          id="horizontal-list-react"
                          ripple={false}
                          className="hover:before:opacity-0"
                          containerProps={{
                            className: "p-0",
                          }}
                        />
                      </ListItemPrefix>
                      <Typography color="blue-gray" className="font-medium">
                        Email
                      </Typography>
                    </label>
                  </ListItem>
                  <ListItem className="p-0">
                    <label
                      htmlFor="horizontal-list-vue"
                      className="flex w-full cursor-pointer items-center px-3 py-2"
                    >
                      <ListItemPrefix className="mr-3">
                        <Checkbox
                          id="horizontal-list-vue"
                          ripple={false}
                          className="hover:before:opacity-0"
                          containerProps={{
                            className: "p-0",
                          }}
                        />
                      </ListItemPrefix>
                      <Typography color="blue-gray" className="font-medium">
                        Phone
                      </Typography>
                    </label>
                  </ListItem>
                </List>
                <Textarea
                  color="cyan"
                  variant="static"
                  className="text-gray"
                  size="lg"
                  label="Massage"
                ></Textarea>
              </div>
              <Button color="gray" className="mt-6" fullWidth>
                Send A Message
              </Button>
            </form>
          </Card>
        </div>
        <div className="lg:block hidden p-4 pr-24">
            <Typography variant="h4" color="gray">
              We cover Over 200 Miles Around
            </Typography>
          <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3228.401512182901!2d-86.60420452503286!3d35.98605491317042!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88647317ec13b643%3A0xad8ae0f138b34ca3!2s6130%20Steve%20Scarlett%20Pl%2C%20La%20Vergne%2C%20TN%2037086%2C%20EE.%20UU.!5e0!3m2!1ses-419!2sni!4v1692031334163!5m2!1ses-419!2sni" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
