import React from "react";
import {
  Typography,
} from "@material-tailwind/react";
import Back1 from "../img/call.jpg";
import { Link } from "react-router-dom";

const ComponentFr = () => {
  return (
    <div className="lg:h-3/5">
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-0 lg:h-3/5">
        <div className="lg:block hidden">
          <img src={Back1} className=" w-full" />
        </div>
        <div className="bg-contact p-16">
          <h2 className="text-[25px] text-blue-900 font-bold">
            You Are Our #1 Priority
          </h2>
          <Typography className="text-white text-[22px]  font-medium">
            <span className="text-[55px] text-white font-bold">
              We Love What We Do
            </span>
            <br />
            PERDOMO ROOFING CORP, we are a reliable and reputable company, we specialize in roofing, with 10 years of experience in the market, located in Vergne, TN, we are specialists in Burnished Slate, Standing Seam Metal Roofing, TPO Roofing Installation/Repair, EPDM Roofing, Standing Seam Metal Roofinging Installation/Repair and roofing in general, whether residential or commercial.
            Leave your roof in the hands of PERDOMO ROOFING CORP and you will get a quality service!

          </Typography>
          <br />
          <Link className="bg-blue-800 hover:bg-blue-900 text-white font-bold py-2 px-4 rounded">
            Free Estimates
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ComponentFr;
