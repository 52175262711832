import React from "react";
import { AiFillCheckCircle } from "react-icons/ai";

const WhyChooseUs = () => {
  return (
    <div>
      <div className="grid lg:grid-cols-3 grid-cols-1 gap-4 py-4 lg:px-24">
        <div className="bg-gray-800 p-8 text-white">
          <AiFillCheckCircle className="text-4xl" />
          <br />
          <h2 className="text-2xl font-bold">Mission</h2>
          <br />
          <p>
            Provide roofing services in general with excellent results, in a
            responsible and professional manner, using materials with high
            quality standards. As a responsible company we are committed to the
            continuous training of our staff hand in hand with technological
            growth, to ensure that our customers are more than satisfied with
            our services. Our main objective is the satisfaction of our
            customers, through quality work in such a way that your roof serves
            as a barrier against snow, hail, rain, ice, branches and debris.
            thus ensuring essential protection for your home.
          </p>
        </div>
        <div className="bg-gray-800 p-8 text-white">
          <AiFillCheckCircle className="text-4xl" />
          <br />
          <h2 className="text-2xl font-bold">Vision</h2>
          <br />
          <p>
            To be a leading company in the provision of maintenance, repair and
            installation services for roofs and covers, providing a high level
            of satisfaction to our customers by offering excellent technical
            advice.
          </p>
        </div>
        <div className="bg-gray-800 p-8 text-white">
          <AiFillCheckCircle className="text-4xl" />
          <br />
          <h2 className="text-2xl font-bold">Why choose us?</h2>
          <br />
          <p>
            Perdomo Roofing Corp, is a reliable company, since we have all the
            legal and insurance requirements, we also have highly qualified
            personnel, in this way we are guaranteeing an excellent service to
            our clients. By hiring us you are opting for quality work,
            responsibly and you will have a magnificent result. We offer a free
            estimate. You can contact us by phone (615) 707-4212, by email
            orvinperdomo1982@gmail.com or contact@perdomoroofingcorp.com, or by
            filling out our contact form for any questions about your needs and
            concerns. It will be a pleasure to assist you!
          </p>
        </div>
      </div>
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:px-32 p-12">
        <div className="lg:text-right text-center px-8">
            <span className="font-bold text-gray-700"> 24/7 EMERGENCIES</span>
            <h2 className="font-bold text-4xl text-gray-900">CONTACT US EASILY FROM YOUR PHONE OR A LAPTOP</h2>
        </div>
        <div className="px-8 flex lg:text-normal text-center items-center lg:border-l-2">
            <p className="text-xl">Perdomo Roofing Corp is Located in La Vergne, Tennessee, Call us at (615) 707-4212</p>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
