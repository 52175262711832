import { Typography } from "@material-tailwind/react";
import React from "react";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import { AiFillCheckCircle } from "react-icons/ai";
import WhyChooseUs from "../components/WhyChooseUs";
import ComponentFr from "../components/ComponentFr";
import { Link } from "react-router-dom";
import Img1 from "../img/portfolio/1.jpg";
import Img2 from "../img/portfolio/2.jpg";
import Img3 from "../img/portfolio/3.jpg";
import Img4 from "../img/portfolio/4.jpg";

export const About = () => {
  
  return (
    <div>
      <div className="header-title px-24 py-32">
        <Link to={'/'} className="text-[25px] text-white">Home</Link>
        <h2 className="font-bold text-[60px] text-white">About Us</h2>
      </div>
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        className="grid lg:grid-cols-2 grid-cols-1 gap-4 py-24 lg:px-24 px-4"
      >
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:py-36 lg:py-12">
          <img className="m-2" src={Img1} alt="" srcset="" />
          <img className="m-2" src={Img2} alt="" srcset="" />
          <img className="m-2" src={Img3} alt="" srcset="" />
          <img className="m-2" src={Img4} alt="" srcset="" />
        </div>
        <div className="flex flex-col lg:ml-8 justify-center">
          <h2 className="text-[50px] text-gray-900 font-bold">
            We Have 10 Years Of Experience
          </h2>
          <Typography className="text-gray-700 font-medium text-justify">
            <span className="text-[35px] text-gray-700 font-bold">
              About Us
            </span>
            <br />
            PERDOMO ROOFING CORP, we are a reliable and reputable company, we specialize in roofing, with 10 years of experience in the market, located in Vergne, TN, we are specialists in Burnished Slate, Standing Seam Metal Roofing, TPO Roofing Installation/Repair, EPDM Roofing, Shingle Roofing Installation/Repair and roofing in general, whether residential or commercial.
            Leave your roof in the hands of PERDOMO ROOFING CORP and you will get a quality service!

          </Typography>
          {/* <Tabs className="mt-8 z-0" id="custom-animation" value="whychooseus">
            <TabsHeader>
              {data.map(({ label, value }) => (
                <Tab key={value} value={value}>
                  {label}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody
              animate={{
                initial: { y: 250 },
                mount: { y: 0 },
                unmount: { y: 250 },
              }}
              color="green"
            >
              {data.map(({ value, desc }) => (
                <TabPanel className="text-justify" key={value} value={value}>
                  {desc}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs> */}
        </div>
      </div>     
      <WhyChooseUs/>


      {/* <ComponentFr/> */}

    </div>
  );
};
