import React from "react";
import { Carousel, Typography, Button } from "@material-tailwind/react";
import S1 from '../img/slider/1.jpg'
import S2 from '../img/slider/2.jpg'
import S3 from '../img/slider/3.jpg'
import { Link } from "react-router-dom";
import { AiOutlineTool } from "react-icons/ai";

const CarouselComponent = () => {
  return (
    <div>
        <Carousel autoplay loop transition={true} className="rounded-none h-[500px] ">
        <div className="relative w-full">
            <img
            src={S1}
            alt="image 1"
            className="h-[500px] w-full object-cover"
            />
            <div className="absolute inset-0 grid h-[500px] w-full place-items-center bg-black/50">
            <div className="lg:w-11/12 w-[70%]">
                <Typography
                variant="h1"
                color="white"
                className="mb-4 text-xl md:text-4xl lg:text-5xl"
                >
                WELCOME TO PERDOMO ROOFING CORP
                </Typography>
                <Typography
                variant="lead"
                color="white"
                className="mb-4 opacity-80 text-lg"
                >
                SPECIALISTS IN ALL TYPES OF METAL ROOFS AND ROOFS IN GENERAL
                </Typography>
                <div className="flex justify-start gap-2">
                    <Link to={'/contact'}>
                        <Button size="lg" color="white">
                        Contact
                        </Button>
                    </Link>
                    <Link to={'/services'}>
                        <Button size="lg" color="blue">
                        Services
                        </Button>
                    </Link>
                </div>
            </div>
            </div>
        </div>
        <div className="relative h-full w-full">
            <img
            src={S2}
            alt="image 2"
            className="h-full w-full object-cover"
            />
            <div
            className="absolute inset-0 grid h-full w-full place-items-center bg-black/50"
            >
            <div className="lg:w-11/12 w-[70%]">
                <Typography
                variant="h1"
                color="white"
                className="mb-4 text-xl md:text-4xl lg:text-5xl "
                >
                WE USE THE BEST QUALITY MATERIALS
                </Typography>
                <Typography
                variant="lead"
                color="white"
                className="mb-4 opacity-80 text-lg"
                >
                YOU CAN TRUST OUR TEAM OF EXPERTS
                </Typography>
                <div className="flex justify-start gap-2">
                    <Link to={'/contact'}>
                        <Button size="lg" color="white">
                        Contact
                        </Button>
                    </Link>
                    <Link to={'/services'}>
                        <Button size="lg" color="blue">
                        Services
                        </Button>
                    </Link>
                </div>
            </div>
            </div>
        </div>
        <div className="relative h-full w-full">
            <img
            src={S3}
            alt="image 3"
            className="h-full w-full object-cover"
            />
            <div className="absolute inset-0 grid h-full w-full place-items-center bg-black/50">
            <div className="lg:w-11/12 w-[70%]">
                <Typography
                variant="h1"
                color="white"
                className="mb-4 text-xl md:text-4xl lg:text-5xl"
                >
                WE HAVE MORE THAN 10 YEARS OF EXPERIENCE
                </Typography>
                <Typography
                variant="lead"
                color="white"
                className="mb-4 opacity-80 text-lg"
                >
                PROTECT YOUR HOME WITH DURABLE AND RESISTANT MATERIALS
                </Typography>
                <div className="flex justify-start gap-2">
                    <Link to={'/contact'}>
                        <Button size="lg" color="white">
                        Contact
                        </Button>
                    </Link>
                    <Link to={'/services'}>
                        <Button size="lg" color="blue">
                        Services
                        </Button>
                    </Link>
                </div>
            </div>
            </div>
        </div>
        </Carousel>
        {/* <div className="grid lg:grid-cols-7 grid-cols-1 gap-0 lg:w-3/4 w-full">
            <div className="flex bg-[#042073] text-center px-4 py-8 lg:col-span-5 justify-center items-center">
                <AiOutlineTool className="text-white text-[35px]"/>
                <h2 className="text-white font-bold text-xl ml-4">Competitive prices! </h2>
            </div>
            <Link to={'/contact'}
                className="flex justify-center items-center lg:col-span-2 bg-btn text-white text-[20px] font-bold py-4"
            >
                Book an Appointment
            </Link>
        </div> */}
    </div>
  );
};

export default CarouselComponent;
