import React, { useState } from "react";
import S1 from "../img/services/s1.jpg";
import S2 from "../img/services/s2.jpg";
import S3 from "../img/services/s3.jpg";
import S4 from "../img/services/s4.jpg";
import S5 from "../img/services/s5.jpg";
import S6 from "../img/services/s6.jpg";
import { AiOutlineCheck } from "react-icons/ai";
import { Link } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogHeader,
  DialogBody,
  DialogFooter,
  Typography,
} from "@material-tailwind/react";

const Services = () => {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [open6, setOpen6] = React.useState(false);

  const handleOpen = () => setOpen(!open);
  const handleOpen2 = () => setOpen2(!open2);
  const handleOpen3 = () => setOpen3(!open3);
  const handleOpen4 = () => setOpen4(!open4);
  const handleOpen5 = () => setOpen5(!open5);
  const handleOpen6 = () => setOpen6(!open6);

  const services = [
    {
      title: "Burnished Slate",
      description: "12 years of Experience",
      paragraph: `Our corrugated metal roofs are available in burnished slate with a PVDF paint finish. PVDF resin-based coatings offer long-lasting performance in demanding architectural coating applications. PVDF (polyvinylidene fluoride) resins are used by licensed industrial paint manufacturers to formulate tough, long-lasting coatings. We have an extensive catalog and personalized advice to be able to leave your project in the best hands, check it out!`,
      img: S1,
      id: 1,
    },
    {
      title: "Standing Seam Metal Roofing",
      description: "We Speak English and Spanish",
      paragraph: `At Perdomo Roofing Coorp, we are specialists in Standing Seam Metal Roof Panels, which we fasten together with hidden fasteners. Originally, the clips fit the vertical legs and were used to fasten the panel to the ceiling substructure. However, as panel options have increased, it is common to see clipless panels included in this family of products.

      Perdomo Roofing Coorp Offers a variety of standing seams that can be curved on the jobsite, reducing transportation costs and ensuring a proper fit. Contact the experts, with the best prices and guaranteed material.
      `,
      img: S2,
      id: 2,
    },
    {
      title: "TPO Roofing Installation/Repair",
      description: "We Accepts Venmo, Paypal, Zelle, Cash App",
      paragraph: `TPO roofs are becoming increasingly popular for commercial properties due to their strength and durability. This low-maintenance material can last for many years if properly cared for and maintained, often outlasting traditional materials such as asphalt shingle roofing. TPO roofs are made from thermoplastic polyolefin (TPO) rubber or PVC plastic with an added layer of protective coating, making them less susceptible to damage from hazardous elements such as UV rays, extreme temperatures, pollutants, high winds, and fire. . At Perdomo Roofing Coorp, we make these qualities of TPO roofs an excellent long-term investment that offers a variety of environmental benefits, such as increased energy efficiency and a lower carbon footprint. Contact the professionals for a free inspection.`,
      img: S3,
      id: 3,
    },

    {
      title: "EPDM Roofing",
      description: "We Accepts Venmo, Paypal, Zelle, Cash App",
      paragraph: `EPDM is an extremely durable synthetic rubber (ethylene propylene diene terpolymer) roofing membrane widely used on low-slope buildings in the United States and around the world. Its two main ingredients, ethylene and propylene, are derived from petroleum and natural gas. EPDM is available in black and white, and is sold in a wide variety of widths, ranging from 7.5' to 50', and in two thicknesses, 45 and 60 mils. EPDM can be installed either fully adhered, mechanically bonded, or ballasted, with the roofing system seams sealed with liquid adhesives or specially formulated tape. We are the professionals for which we instruct you in the best materials and the best option for your home or business, Perdomo Roofing Coorp, the most complete company in all of Vergne, TN and its surroundings areas. `,
      img: S4,
      id: 4,
    },
    {
      title: "Shingle Roofing Installation/Repair",
      description: "We Accepts Venmo, Paypal, Zelle, Cash App",
      paragraph: `If you're looking to install or repair your roof, an asphalt roof installation or repair is a great option, especially if you're working on a tight budget. You can choose from two different types of shingles: 3-tab shingles are typically the more economical option, with a slim, flat design, while dimensional shingles offer more protection and a sleeker finish. At Perdomo Roofing Coorp, we accommodate our clients' budgets, since the cost of installing asphalt shingles is relatively low compared to other options such as metal or tiles, so it is worth hiring a professional to do it. do for you. Call now and receive a free inspection or an estimate, we are the professionals when it comes to roofing.`,
      img: S5,
      id: 5,
    },
    {
      title: "Other Services",
      description: "We Accepts Venmo, Paypal, Zelle, Cash App",
      paragraph: `General Roofing`,
      img: S6,
      id: 6,
    },
  ];

  const selectServices = (id) => {
    switch (id) {
      case 1:
          handleOpen();
        break;
      case 2: 
        handleOpen2();
      break;

      case 3: 
        handleOpen3();
      break;
      case 4: 
        handleOpen4();
      break;
      case 5: 
        handleOpen5();
      break;
      case 6: 
        handleOpen6();
      break;
      default:
        break;
    }
  }

  return (
    <div className="overflow-y-scroll">
      <div className="header-title px-24 py-32">
        <Link to={"/"} className="text-[25px] text-white">
          Home
        </Link>
        <h2 className="font-bold text-[60px] text-white">Services</h2>
      </div>
      <div className="py-24 lg:px-32 px-2">
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
          {services.map((ser) => (
            <div className="">
              <img
                width="100%"
                className="h-[60%]"
                src={ser.img}
                alt=""
                srcset=""
              />
              <div className="flex bg-gray-900 py-8 px-4 w-full items-center">
                <h2 className="font-bold text-[#ffffff]">{ser.title}</h2>
                <div className="flex mx-4">
                  <button onClick={() => selectServices(ser.id)} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded ">
                    +
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        {/* <div
          className="grid lg:grid-cols-3 grid-cols-1 gap-4 mt-16 "
          data-aos="fade-up"
          data-aos-anchor-placement="top-center"
        >
          <p className="col-span-2 text-gray-600">
            Do you need a professional roofing job for your home? At Perdomo
            Roofing Corp we have a variety of services in this industry and 12
            years of experience that support our services, in addition to having
            a highly professional and qualified team so that they can provide
            support when requesting one of our different roofing services.
          </p>
          <div className="col-span-1">
            <h2 className="font-bold text-2xl">Our Advantages</h2>
            <ul className="mt-4">
              <li>
                <div className="flex items-center">
                  <AiOutlineCheck className="mr-4 text-green-700 font-bold" />
                  <p className="text-gray-600">Free Estimates</p>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <AiOutlineCheck className="mr-4 text-green-700 font-bold" />
                  <p className="text-gray-600">We Speak English and Spanish</p>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <AiOutlineCheck className="mr-4 text-green-700 font-bold" />
                  <p className="text-gray-600">WE COVER 50 MILES</p>
                </div>
              </li>
              <li>
                <div className="flex items-center">
                  <AiOutlineCheck className="mr-4 text-green-700 font-bold" />
                  <p className="text-gray-600">12 years of Experience</p>
                </div>
              </li>
            </ul>
          </div>
        </div> */}
      </div>

      <Dialog  size='lg' open={open} handler={handleOpen}>
        <DialogHeader>Burnished Slate</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S1} alt="" srcset="" />
            <Typography className="font-normal">
            Our corrugated metal roofs are available in burnished slate with a PVDF paint finish. PVDF resin-based coatings offer long-lasting performance in demanding architectural coating applications. PVDF (polyvinylidene fluoride) resins are used by licensed industrial paint manufacturers to formulate tough, long-lasting coatings. We have an extensive catalog and personalized advice to be able to leave your project in the best hands, check it out!
            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog  size='lg' open={open2} handler={handleOpen2}>
        <DialogHeader>Standing Seam Metal Roofing</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S2} alt="" srcset="" />
            <Typography className="font-normal">
            At Perdomo Roofing Coorp, we are specialists in Standing Seam Metal Roof Panels, which we fasten together with hidden fasteners. Originally, the clips fit the vertical legs and were used to fasten the panel to the ceiling substructure. However, as panel options have increased, it is common to see clipless panels included in this family of products.

Perdomo Roofing Coorp Offers a variety of standing seams that can be curved on the jobsite, reducing transportation costs and ensuring a proper fit. Contact the experts, with the best prices and guaranteed material.

            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen2}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog  size='lg' open={open3} handler={handleOpen3}>
        <DialogHeader>TPO Roofing Installation/Repair
</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S3} alt="" srcset="" />
            <Typography className="font-normal">
            TPO roofs are becoming increasingly popular for commercial properties due to their strength and durability. This low-maintenance material can last for many years if properly cared for and maintained, often outlasting traditional materials such as asphalt shingle roofing. TPO roofs are made from thermoplastic polyolefin (TPO) rubber or PVC plastic with an added layer of protective coating, making them less susceptible to damage from hazardous elements such as UV rays, extreme temperatures, pollutants, high winds, and fire. . At Perdomo Roofing Coorp, we make these qualities of TPO roofs an excellent long-term investment that offers a variety of environmental benefits, such as increased energy efficiency and a lower carbon footprint. Contact the professionals for a free inspection.
            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen3}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog  size='lg' open={open4} handler={handleOpen4}>
        <DialogHeader>EPDM Roofing</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S4} alt="" srcset="" />
            <Typography className="font-normal">
            EPDM is an extremely durable synthetic rubber (ethylene propylene diene terpolymer) roofing membrane widely used on low-slope buildings in the United States and around the world. Its two main ingredients, ethylene and propylene, are derived from petroleum and natural gas. EPDM is available in black and white, and is sold in a wide variety of widths, ranging from 7.5' to 50', and in two thicknesses, 45 and 60 mils. EPDM can be installed either fully adhered, mechanically bonded, or ballasted, with the roofing system seams sealed with liquid adhesives or specially formulated tape. We are the professionals for which we instruct you in the best materials and the best option for your home or business, Perdomo Roofing Coorp, the most complete company in all of Vergne, TN and its surroundings areas. 
            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen4}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog  size='lg' open={open5} handler={handleOpen5}>
        <DialogHeader>Shingle Roofing Installation/Repair</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S5} alt="" srcset="" />
            <Typography className="font-normal">
            If you're looking to install or repair your roof, an asphalt roof installation or repair is a great option, especially if you're working on a tight budget. You can choose from two different types of shingles: 3-tab shingles are typically the more economical option, with a slim, flat design, while dimensional shingles offer more protection and a sleeker finish. At Perdomo Roofing Coorp, we accommodate our clients' budgets, since the cost of installing asphalt shingles is relatively low compared to other options such as metal or tiles, so it is worth hiring a professional to do it. do for you. Call now and receive a free inspection or an estimate, we are the professionals when it comes to roofing.

            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen5}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
      <Dialog  size='lg' open={open6} handler={handleOpen6}>
        <DialogHeader>Other Services</DialogHeader>
        <DialogBody divider className="h-auto max-h-72 overflow-scroll">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-4">
            <img className="" src={S6} alt="" srcset="" />
            <Typography className="font-normal">
            General Roofing
            </Typography>            
          </div>
        </DialogBody>
        <DialogFooter className="space-x-2">
          <Button variant="outlined" color="red" onClick={handleOpen6}>
            close
          </Button>
        </DialogFooter>
      </Dialog>
    </div>
  );
};

export default Services;
