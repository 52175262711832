import { createContext, useContext, useState } from "react";

export const dbContext = createContext();


export const useDb = () => {
    const context = useContext(dbContext);
    return context;
}

export function DbProvider({children}) {

    return (
        <dbContext.Provider
            value={{
                
            }}
        >
            {children}
        </dbContext.Provider>
    )
}