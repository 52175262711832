import React, { useEffect } from "react";
import Score from "../components/Score";
import {
  Button,
  Dialog,
  Card,
  CardHeader,
  CardBody,
  DialogHeader,
  Input,
  Checkbox,
  Typography,
  Select,
  Option,
  List,
  ListItem,
  ListItemPrefix,
  Textarea
} from "@material-tailwind/react";
import S1 from "../img/services/s1.jpg";
import S2 from "../img/services/s2.jpg";
import S3 from "../img/services/s3.jpg";
import CarouselComponent from "../components/CarouselComponent";
import ContactForm from "../components/ContactForm";
import Img1 from "../img/portfolio/2.jpg";
import Img2 from "../img/portfolio/3.jpg";
import Img3 from "../img/portfolio/11.jpg";
import Img4 from "../img/portfolio/50.jpg";
import ComponentFr from "../components/ComponentFr";
import WhyChooseUs from "../components/WhyChooseUs";
import Logo from '../img/logo.png'
import { AiOutlineClose } from "react-icons/ai";
import { Link } from "react-router-dom";

const Home = () => {
  const [size, setSize] = React.useState(null);
 
  const handleOpen = (value) => setSize(value);
  const data = [
    {
      label: "WHY CHOOSE US?",
      value: "whychooseus",
      desc: `At Perdomo Roofing Corp, we value your trust in us and strive to provide a positive experience with every interaction.

      We understand the importance of staying current with the latest trends and techniques in the landscaping field. We continue to learn and train to provide you with innovative and creative solutions to suit your needs and preferences.
      
      Our personalized approach allows us to adapt to your specific requirements and provide you with outstanding results.
      
      Explore our portfolio of projects, from satisfied clients, to get an idea of our work. We are confident that you will be convinced by our experience and commitment to excellence.`,
    },
    {
      label: "OUR MISSION",
      value: "mission",
      desc: `To turn your outdoor spaces into true natural works of art, providing you with high quality landscaping and maintenance services. We seek to create unique and personalized landscapes that reflect the style and vision of each client, either through the planning and design of new projects, or through the regular maintenance of existing gardens.`,
    },

    {
      label: "OUR VISION",
      value: "vision",
      desc: `We seek to inspire our clients to connect with nature and enjoy outdoor spaces that reflect their style and personality.

      We aspire to build strong, long-lasting relationships with our clients based on trust, transparency and effective communication. We want to become their trusted partner, providing them with personalized service and an exceptional experience at every stage of our work together.
      `,
    },
  ];
  const services = [
    {
      title: "Burnished Slate",
      description: "12 years of Experience",
      paragraph: `YOUR GARDEN IN GOOD HANDS!

      Garden maintenance is one of the main services we offer at Perdomo Roofing Corp.
      `,
      img: S1,
    },
    {
      title: "Standing Seam Metal Roofing",
      description: "We Speak English and Spanish",
      paragraph: `THE GARDEN OF YOUR DREAMS, TAILOR-MADE.

      From conceptual design to final installation, we take care of creating stunning landscapes
      
      `,
      img: S2,
    },
    {
      title: "TPO Roofing Installation/Repair",
      description: "We Accepts Venmo, Paypal, Zelle, Cash App",
      paragraph: `WE CREATE SUSTAINABLE AND ENVIRONMENTALLY FRIENDLY GARDENS.

      At Perdomo Roofing Corp, we specialize in hardscaping, which involves the design
      
      `,
      img: S3,
    },
  ];

  useEffect(() => {
    // ⛔️ Unexpected use of 'location'. eslint(no-restricted-globals)
    if(window.innerWidth > 435) {
      handleOpen("md");
      
    } else{
      handleOpen("xxl");      
    }
  }, []);

  return (
    <div>
      <Dialog
        open={
          size === "xs" ||
          size === "sm" ||
          size === "md" ||
          size === "lg" ||
          size === "xl" ||
          size === "xxl"
        }
        size={size || "md"}
        handler={handleOpen}
        className="bg-transparent shadow-none "
      >
        <Card className="mx-auto w-full max-h-[600px] overflow-y-scroll">
          <DialogHeader className="flex flex-row-reverse ">
            <AiOutlineClose onClick={handleOpen} className="text-red-500"/>
          </DialogHeader>
          <CardBody className="flex flex-col gap-4 ">
            <div className="flex justify-center items-center">
              <img src={Logo} className="w-2/5" alt="" srcset="" />
            </div>
            <div className="flex justify-center items-center w-full">
              <Card color="transparent" shadow={false}>
                <Typography variant="h4" color="gray">
                  Perdomo Roofing Corp
                </Typography>
                <Typography color="gray" className="mt-1 font-normal">
                  Free Estimates Requirement or get a Quoter.
                </Typography>
                <form className="mt-8 mb-2 w-80 max-w-screen-lg sm:w-96">
                  <div className="mb-4 flex flex-col gap-6">
                    <Input color="gray" size="sm" label="Name" />
                    <Input color="gray" size="sm" label="Last Name" />
                    <Input color="gray" size="sm" label="Email" />
                    <Input color="gray" size="sm" label="Address" />
                    <Input color="gray" size="sm" label="Phone Number" />
                    <Select label="Type You Services You Need">
                      <Option>Burnished Slate</Option>
                      <Option>Standing Seam Metal Roofing</Option>
                      <Option>TPO Roofing Installation/Repair</Option>
                      <Option>EPDM Roofing</Option>
                      <Option>
                        Standing Seam Metal Roofinging Installation/Repair
                      </Option>
                      <Option>General Roofing</Option>
                    </Select>
                    <h2>Best Way To Contact You</h2>
                    <List className="flex-row">
                      <ListItem className="p-0">
                        <label
                          htmlFor="horizontal-list-react"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                        >
                          <ListItemPrefix className="mr-3">
                            <Checkbox
                              id="horizontal-list-react"
                              ripple={false}
                              className="hover:before:opacity-0"
                              containerProps={{
                                className: "p-0",
                              }}
                            />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                            Email
                          </Typography>
                        </label>
                      </ListItem>
                      <ListItem className="p-0">
                        <label
                          htmlFor="horizontal-list-vue"
                          className="flex w-full cursor-pointer items-center px-3 py-2"
                        >
                          <ListItemPrefix className="mr-3">
                            <Checkbox
                              id="horizontal-list-vue"
                              ripple={false}
                              className="hover:before:opacity-0"
                              containerProps={{
                                className: "p-0",
                              }}
                            />
                          </ListItemPrefix>
                          <Typography color="blue-gray" className="font-medium">
                            Phone
                          </Typography>
                        </label>
                      </ListItem>
                    </List>
                    <Textarea
                      color="cyan"
                      variant="static"
                      className="text-gray"
                      size="lg"
                      label="Massage"
                    ></Textarea>
                  </div>
                  <Button color="gray" className="mt-6" fullWidth>
                    Send A Message
                  </Button>
                </form>
              </Card>
            </div>
          </CardBody>
        </Card>
      </Dialog>
      <CarouselComponent />
      {/* <Images /> */}
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        className="grid lg:grid-cols-2 grid-cols-1 gap-4 py-24 lg:px-24 px-4"
      >
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-4 lg:py-36 lg:py-12">
          <img className="m-2" src={Img1} alt="" srcset="" />
          <img className="m-2" src={Img2} alt="" srcset="" />
          <img className="m-2" src={Img3} alt="" srcset="" />
          <img className="m-2" src={Img4} alt="" srcset="" />
        </div>
        <div className="flex flex-col lg:ml-8 justify-center">
          <h2 className="text-[50px] text-gray-900 font-bold">
            We Have 10 Years Of Experience
          </h2>
          <Typography className="text-gray-700 font-medium ">
            <span className="text-[35px] text-green-700 font-bold">
              We Love What We Do
            </span>
            <br />
            We specialize in installation, maintenance and everything related to roofs with high quality standards to provide greater protection, durability and beautification to your home.
            <br />We have 10 years of experience in Residential and Commercial, guaranteeing an excellent job.
            <br />At PERDOMO ROOFING CORP we stand out for having the best materials as well as highly qualified personnel, we are committed to offering and assisting our clients to please them in the best way. We are at your disposal to entrust the protection of your home with a quality roof in the hands of experts! Call now and get your estimate and inspection totally free. Call Now (615) 707-4212

          </Typography>
          {/* <Tabs className="mt-8 z-0" id="custom-animation" value="whychooseus">
            <TabsHeader>
              {data.map(({ label, value }) => (
                <Tab key={value} value={value}>
                  {label}
                </Tab>
              ))}
            </TabsHeader>
            <TabsBody
              animate={{
                initial: { y: 250 },
                mount: { y: 0 },
                unmount: { y: 250 },
              }}
              color="green"
            >
              {data.map(({ value, desc }) => (
                <TabPanel className="text-justify" key={value} value={value}>
                  {desc}
                </TabPanel>
              ))}
            </TabsBody>
          </Tabs> */}
        </div>
      </div>
      {/* <Score /> */}
      <ComponentFr />
      <WhyChooseUs />
      <div
        data-aos="fade-up"
        data-aos-anchor-placement="top-center"
        className="py-24 lg:px-32 px-2 mb-8"
      >
        {/* <div className="text-center mb-24">
          <p className="text-[12px] text-green-900 font-bold">
            WE COVER 50 MILES !!
          </p>
          <h2 className="text-[25px] font-bold">Professional Services</h2>
        </div> */}
        <div className="grid lg:grid-cols-3 grid-cols-1 gap-4">
          {services.map((ser) => (
            <div className="mb-24">
              <img
                width="100%"
                className="h-[100%]"
                src={ser.img}
                alt=""
                srcset=""
              />
              <div className="flex bg-gray-900 py-8 px-4 w-full items-center">
                <h2 className="font-bold text-[#ffffff]">{ser.title}</h2>
                <div className="flex mx-4">
                  <Link to={"/services"} class="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded ">
                    +
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <br /> <br /> <br />
      <div className="my-8">
        <ContactForm />
      </div>
      <br /> <br /> <br />
    </div>
  );
};

export default Home;
